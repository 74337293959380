import React, { useState } from 'react';
import Logo from '../components/Logo';
import MainLayout from '../components/MainLayout';

const Login = () => {
    const [isLogin, setIsLogin] = useState(true);

    const toggleForm = () => {
        setIsLogin(!isLogin);
    };

    return (
        <MainLayout>
            <div className=" flex-grow items-center justify-center bg-gray-100">
                <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                    <Logo />
                    <h2 className="text-2xl font-bold mb-6 text-center">
                        {isLogin ? 'Login' : 'Register'}
                    </h2>
                    <form>
                        {!isLogin && (
                            <div className="mb-4">
                                <label className="block text-gray-700">Name</label>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border rounded"
                                    placeholder="Enter your name"
                                />
                            </div>
                        )}
                        <div className="mb-4">
                            <label className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                className="w-full px-3 py-2 border rounded"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Password</label>
                            <input
                                type="password"
                                className="w-full px-3 py-2 border rounded"
                                placeholder="Enter your password"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
                        >
                            {isLogin ? 'Login' : 'Register'}
                        </button>
                    </form>
                    <p className="mt-4 text-center">
                        {isLogin ? "Don't have an account?" : 'Already have an account?'}{' '}
                        <button
                            onClick={toggleForm}
                            className="text-blue-500 hover:underline"
                        >
                            {isLogin ? 'Register' : 'Login'}
                        </button>
                    </p>
                </div>
            </div>
        </MainLayout>
    );
};

export default Login;