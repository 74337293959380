import {
    createBrowserRouter,
  } from "react-router-dom";
import Home from "./routes/Home";
import Login from "./routes/Login";

const routes = [
  { path: "/", element: <Home />, },
  { path: "/login", element: <Login />, },
]

export default routes;