import MainLayout from "../components/MainLayout";

const Home = () => {
    return (
        <MainLayout>
            <h1 className="text-2xl">Home</h1>
            <p>Welcome to my Hacklabs Pro!</p>
        </MainLayout>
    );
}

export default Home;
