import React from 'react';
import { useEthers } from '@usedapp/core'

const ConnectButton = () => {
    const { account, deactivate, activateBrowserWallet } = useEthers()
    // 'account' being undefined means that we are not connected.
    if (account) return <button onClick={() => deactivate()}>Disconnect to logout</button>
    else return <button onClick={() => activateBrowserWallet()}>Login with wallet</button>
  }

const MainLayout = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col">
            <header className="bg-blue-600 text-white p-4">
                <h1 className="text-2xl">Hacklabs</h1>
                <nav className='mt-4 flex flex-col justify-between sm:flex-row'>
                    <ul className="flex space-x-4 text-white text-sm uppercase font-semibold">
                        <li><a href="/" className="p-2 bg-blue-800" disabled={true}>Home</a></li>
                        <li><a href="/about" className="">Get Started</a></li>
                        <li><a href="/about" className="">Web</a></li>
                        <li><a href="/about" className="">Mobile</a></li>
                        <li><a href="/about" className="">Cloud</a></li>
                        <li><a href="/about" className="">Binexp</a></li>
                        <li><a href="/about" className="">IOT</a></li>
                    </ul>
                    <ul className="flex space-x-4 text-white text-sm uppercase font-semibold">
                        <li><ConnectButton /></li>
                    </ul>
                </nav>
            </header>
            <main className="flex-grow">
                {children}
            </main>
            <footer className="bg-gray-800 text-white p-4 text-center">
                <p>&copy; {new Date().getFullYear()} Hacklabs. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default MainLayout;